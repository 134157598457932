<template>
  <div class="fragment">

    <div class="content-top-line content-top-line--with-bottom-line"
         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
      <div class="admin-edit" @click="editTranslate([
          '',
        ])"></div>

      <div class="content-tabs content-tabs--separator">

      </div>

      <div class="content-top-line-wrap">
        <ToggleFilterButton :count="countFilterParams" @toggleFilter="$emit('toggleFilter')" :showFilter="showFilter" />
      </div>
    </div>

    <div class="light-bg-block">
      <div class="btn-left-block ">
        <div class="btn-left-block-i">
          <div class="btn-left-block__list">
            <div class="btn-left-block__list__i">
              <PrintInvoice
                  class="btn-left-block-i--link"
                  :datePicker="true"
                  :datePickerRange="false"
                  :datePickerTxt="$t('common_IE599Report.localization_value.value')"
                  :datePickerFormat="'YYYY-MM-DD'"
                  :datePickerType="'month'"
                  :disabledDates="'currentAndNextMonth'"
                  @changeDate="changeDate"
                  @datePickerBtn="downloadReport"
              />
            </div>
          </div>
        </div>

<!--        <div class="btn-left-block-i">-->
<!--          <DefaultCheckbox-->
<!--              :label="$t('common_selectAll.localization_value.value')"-->
<!--              @input="(val) => $emit('selectAll', val)"-->
<!--          />-->
<!--        </div>-->
      </div>

      <div class="btn-right-block btn-right-block--mobile-wrap">
        <MainButton class="btn-fit-content ml-2"
                    v-if="!isAdmin"
                    :value="$t('IE559_ChangeFormData.localization_value.value')"
                    @click.native="openUserIE599DataPopup = true"
        >
        </MainButton>

        <MainButton class="btn-fit-content ml-2"
                    :value="$t('IE559_regenerateForms.localization_value.value')"
                    @click.native="generateForm"
        >
        </MainButton>
      </div>
    </div>

    <UserIE599DataPopup
        v-if="openUserIE599DataPopup"
        @reload="$emit('reload')"
        @close="openUserIE599DataPopup = false"
    />
  </div>
</template>

<script>
import ToggleFilterButton from "../../../../../../UI/buttons/ToggleFilterButton/ToggleFilterButton";
import UserIE599DataPopup from "@/components/modules/IE559ReportModule/popups/UserIE599DataPopup/UserIE599DataPopup";
import MainButton from "@/components/UI/buttons/MainButton/MainButton";
import PrintInvoice from "@/components/coreComponents/PrintInvoice/PrintInvoice";
// import DefaultCheckbox from "../../../../../../UI/checkboxes/DefaultCheckbox/DefaultCheckbox";


export default {
  name: "IE559ReportHead",
  components: {
    PrintInvoice,
    MainButton,
    UserIE599DataPopup,
    ToggleFilterButton,
    // DefaultCheckbox,
  },

  watch: {
    getFormUserData() {
      if(!this.isAdmin && this.$store.getters.getIE559ReportCommonList.consolidation_form_user_data &&
        !this._.has(this.$store.getters.getIE559ReportCommonList.consolidation_form_user_data, 'company_name')
      ) {
        this.openUserIE599DataPopup = true
      }
    },
  },

  computed: {
    getFormUserData() {
      return this.$store.getters.getIE559ReportCommonList
    }
  },

  props: {
    showFilter: Boolean,
    countFilterParams: Number,
  },

  data() {
    return {
      openUserIE599DataPopup: false,
      dateReport: this.$moment().format('YYYY-MM-DD'),
    }
  },

  created() {

  },

  methods: {
    changeDate(value) {
      this.dateReport = value
    },

    downloadReport() {
      this.generateForm(this.$moment(this.dateReport).format('DD-MM-YYYY'))
    },

    generateForm(date) {
      let text = {
        title: 'common_regenerateForms',
        txt: '',
        yes: 'common_confirm',
        no: 'common_confirmNo'
      }

      let confirm = () => {
        this.$store.dispatch('generateLastForm', date).then(response => {
          switch (this.getResponseStatus(response)) {
              /**
               * Success
               */
            case this.$store.getters.GET_ERRORS.SUCCESS_CODE: {
              this.openNotify('success', 'common_notificationStartGenerate')
              break
            }
              /**
               * Validation Error
               */
            case this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE: {
              let errors = response.response.data.errors
              this.notifyErrorHelper(errors)
              break
            }
              /**
               * Undefined Error
               */
            default: {
              this.openNotify('error', 'common_notificationUndefinedError')
            }
          }
        })

        this.$store.commit('setConfirmDeletePopup', false)
        return true
      }

      this.deleteFunc(text, confirm)
    },

  }
}
</script>

<style scoped>
@media (min-width: 680px) {
  .btn-left-block{
    flex-wrap: wrap;
    max-width: 50%;
  }

  .btn-left-block-i{
    margin-left: 0!important;
    margin-right: 15px;
  }
}

@media (max-width: 680px){
  .btn-right-block{
    display: flex;
    flex-direction: column-reverse;
    align-items: flex-end;
  }

  .btn-right-block .btn-right-block-i{
    /*margin-top: 15px;*/
    margin-right: 0!important;
  }

  .btn-right-block .btn-right-block-i:last-child{
    margin-top: 0;
  }

  .btn-left-block{
    display: flex;
    flex-direction: column;
    max-width: 50%
  }

  .btn-left-block-i{
    margin-left: 10px;
    margin-bottom: 10px;
  }

  .btn-left-block-i:last-child{
    margin-top: 0;
    margin-bottom: 0;
  }

  .btn-left-block-i--link{
    font-size: 12px;
  }

  .light-bg-block{
    align-items: flex-end;
    width: calc(100% + 30px);
    margin: 0 -15px;
  }
}
</style>

